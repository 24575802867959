<template>
  <v-container
    class="products-list-page"
    fluid>
    <member-selector-modal
      :dialog="memberSelectorModal"
      @input="recieveMembers($event)"
      @close-modal="memberSelectorModalClose()" />
    <promotion-detail-modal
      v-model="promotionDeatilModal"
      :promotion="promotion" />
    <v-row class="products-row main-row">
      <v-col cols="2">
        <v-switch
          v-model="switches.importFile"
          label="Import file"
          @change="switchHandler('importFile')" />
      </v-col>
      <v-col
        cols="3"
        class="py-6">
        <csv-reader
          v-model="csvContents"
          :disabled="!switches.importFile"
          @input="csvHandler()" />
      </v-col>
      <v-col cols="2">
        <v-switch
          v-model="switches.code"
          label="ส่งโค้ด"
          @change="switchHandler('code')" />
      </v-col>
      <v-col
        cols="3"
        class="py-6">
        <v-text-field
          v-model="form.code"
          :disabled="!switches.code"
          hide-details
          outlined
          append-icon="mdi-format-list-checkbox"
          dense
          @click:append="codeInputClicked()" />
      </v-col>
      <v-col
        cols="12"
        class="d-flex">
        <v-checkbox
          v-for="(channel, index) in channels"
          :key="`channel-index-${index}`"
          v-model="form.channel"
          class="ml-4"
          :label="channel.label"
          :value="channel.value" />
      </v-col>
      <v-col cols="12">
        <v-combobox
          v-model="form.members"
          prepend-inner-icon="mdi-label"
          append-icon="mdi-account-box-outline"
          outlined
          dense
          deletable-chips
          hide-selected
          label="ผู้รับ"
          multiple
          hide-details
          persistent-hint
          small-chips
          @keypress="$keypressNumber($event)"
          @change="memberTelsCompute($event)"
          @click:append="memberSelectorModalOpen()" />
      </v-col>
      <v-col
        v-if="form.channel.includes('sms')"
        cols="12">
        <v-textarea
          v-model="form.message"
          label="ข้อความที่ต้องการส่งผ่าน SMS"
          persistent-hint
          outlined
          :hint="textHint" />
      </v-col>
      <v-col
        v-if="form.channel.includes('sms')"
        cols="12">
        <v-textarea
          class="text-preview-box"
          readonly
          outlined
          persistent-hint
          hint="ตัวอย่างข้อความ">
          <template v-slot:prepend-inner>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="messagePreview" />
          </template>
        </v-textarea>
      </v-col>
      <v-col
        v-if="form.channel.includes('line')"
        cols="12">
        <line-message-box v-model="lineMessage" />
      </v-col>
      <v-col
        v-if="form.channel.includes('email')"
        cols="12">
        <email-message-box />
      </v-col>
      <v-col
        cols="12"
        class="text-center">
        <v-btn
          :disabled="!readyToSendMessage"
          color="primary"
          @click="sendMessages()">
          ส่งข้อความ
        </v-btn>
      </v-col>
      <v-col
        v-for="(template, index) in templates"
        :key="`templates-${index}`"
        cols="12">
        <h4>{{ `${index+1}. ${template.title}` }}</h4>
        <p class="template-text">
          {{ template.text }}
        </p>
        <v-btn
          small
          class="mb-4"
          @click="useTemplate(template)">
          use
        </v-btn>
        <v-divider />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SmsProvider from '@/resources/SmsProvider'
import PromotionProvider from '@/resources/PromotionProvider'
import LineProvider from '@/resources/LineProvider'
import MemberProvider from '@/resources/MemberProvider'

import BroadcastTemplates from '@/assets/js/BroadcastTemplates'
import PersonalizeMutator from '@/assets/js/PersonlizeMutator'

import MemberSelectorModal from '../components/MemberSelectorModal.vue'
import PromotionDetailModal from '../components/PromotionDetailModal.vue'
import LineMessageBox from '../components/LineMessage.vue'
import EmailMessageBox from '../components/EmailMessageBox.vue'
import CsvReader from '../components/CsvReader.vue'

const PromotionService = new PromotionProvider()
const SmsService = new SmsProvider()
const LineService = new LineProvider()
const MemberService = new MemberProvider()

export default {
  components: {
    EmailMessageBox,
    MemberSelectorModal,
    PromotionDetailModal,
    LineMessageBox,
    CsvReader
  },
  data () {
    return {
      csvContents: [],
      textHint1: '$firstName = ชื่อจริง, $lastName = นามสกุล, $tel = เบอร์โทรศัพท์, $code = โปรโมชั่นโค้ด, $spending = ยอดซื้อสะสม',
      textHint2: '$memberType = ประเภทสมาชิก, $discount = ส่วนลด, $expireCode = วันหมดอายุโค้ด, $birthDate = วันเดือนปีเกิด',
      memberSelectorModal: false,
      promotionDeatilModal: false,
      csvFields: {
        tel: 'tel',
        code: 'code'
      },
      csvData: [],
      form: {
        code: '',
        channel: [],
        members: [],
        message: ''
      },
      lineMessage: [{ type: 'text', originalContentUrl: '', previewImageUrl: '' }],
      promotion: {},
      switches: {
        code: false,
        importFile: false
      },
      memberSelected: []
    }
  },
  computed: {
    channels () {
      return [
        { value: 'sms', label: 'SMS' },
        { value: 'line', label: 'LINE' },
        { value: 'email', label: 'EMAIL' }
      ]
    },
    textHint () {
      let text = ''
      const variables = PersonalizeMutator.personal
      if (this.switches.code) {
        variables.push(...PersonalizeMutator.promotion)
      }

      if (this.switches.importFile) {
        variables.push({
          label: 'โค้ดของแต่ละคน',
          value: '$memberCode'
        })
      }

      variables.forEach((variable) => {
        text += `${variable.value} = ${variable.label}, `
      })

      return text
    },
    templates () {
      return BroadcastTemplates
    },
    messagePreview () {
      const temp = this.form.message || ''
      return this.previewTransform(temp)
    },
    promotionCode () {
      return this.$route.query.code || null
    },
    readyToSendMessage () {
      const hasSmsMessage = this.form.channel.includes('sms') ? !!this.form.message : true
      const hasLineMessage = this.form.channel.includes('line') ? !!this.lineMessage.length > 0 : true
      const hasPath = this.form.channel.length > 0
      const hasReciever = this.form.members.length > 0

      return hasSmsMessage && hasLineMessage && hasPath && hasReciever
    }
  },
  mounted () {
    if (this.promotionCode) {
      this.form.code = this.promotionCode
      this.switches.code = true
      this.getPromotion(this.promotionCode)
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading',
      storeSetting: 'Store/storeSetting'
    }),
    switchHandler (field) {
      if (field === 'code') {
        this.switches.importFile = false
      } else if (field === 'importFile') {
        this.switches.code = false
      }
    },
    csvHandler () {
      this.form.members = this.csvContents.map((each) => this.telFormatter(each.tel))
    },
    async codeInputClicked () {
      if (this.form.code !== this.promotion.code) {
        await this.getPromotion(this.form.code)
      }

      if (this.promotion.code) {
        this.promotionDeatilModal = true
      }
    },
    memberSelectorModalOpen () {
      this.memberSelectorModal = true
    },
    memberSelectorModalClose () {
      this.memberSelectorModal = false
    },
    recieveMembers (members) {
      this.memberSelected = members
      this.form.members = this.memberTelsTransform(members)

      this.memberSelectorModalClose()
    },
    memberTelsTransform (members) {
      return members.map((member) => this.telFormatter(member.tel, member.telInfo?.dialCode))
    },
    async getPromotion (code) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GET PROMOTION...'
        })

        const { data } = await PromotionService.getPromotionByCode(code)
        if (data) {
          this.promotion = data
        } else {
          this.setSnackbar({
            value: true,
            message: 'ไม่พบโค้ดส่วนลดนี้ กรุณากรอกใหม่อีกครั้ง',
            type: 'error'
          })
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET PROMOTION ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    telFormatter (_tel, dialCode = '66') {
      let tel = _tel
      if (tel[0] === '6' && tel[1] === '6') {
        tel = `+${tel}`
      } else if (tel[0] !== '+' && dialCode === '66') {
        tel = `+66${tel.slice(1)}`
      }

      return tel
    },
    async getMembersByTels (payload) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GET MEMBERS DATA...'
        })

        const { data } = await MemberService.getMembersByTels(payload)
        this.memberSelected = data
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET MEMEBERS ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    previewTransform (text) {
      let result = text
      const variables = [...PersonalizeMutator.personal, ...PersonalizeMutator.promotion]
      variables.forEach((variable) => {
        const sampleValue = variable.value.replace('$', '')
        if (PersonalizeMutator[sampleValue]) {
          result = result.replaceAll(variable.value, PersonalizeMutator[sampleValue]())
        }
      })

      return result
    },
    useTemplate (template) {
      this.form.message = template.text

      this.setSnackbar({
        value: true,
        message: `ใช้ Template ${template.title} แล้ว`,
        type: 'success'
      })
    },
    selectPath (members) {
      const results = {
        sms: [],
        line: []
      }

      members.forEach((member) => {
        const found = member.otherOptions.find((option) => option.key === 'line-uid')
        const lineUid = found ? found.value : null
        if (lineUid) {
          results.line.push(member)
        } else {
          results.sms.push(member)
        }
      })

      return results
    },
    async sendMessages () {
      try {
        if (this.memberSelected.length !== this.form.members) {
          await this.getMembersByTels({ tels: this.form.members })
        }

        if (this.form.channel.length > 1) {
          const pathChosens = this.selectPath(this.memberSelected)
          await this.preparedToSendSMS(pathChosens.sms)
          await this.preparedToSendLine(pathChosens.line)
        } else if (this.form.channel[0] === 'sms') {
          this.preparedToSendSMS(this.memberSelected)
        } else if (this.form.channel[0] === 'line') {
          this.preparedToSendLine(this.memberSelected)
        }
      } catch (e) {
        this.setSnackbar({
          value: true,
          message: `[SEND MESSAGE(S) ERROR]: ${e.message}`,
          type: 'error'
        })
        console.error(`[SEND MESSAGE(S) ERROR]: ${e.message}`)
      }
    },
    async preparedToSendSMS (members) {
      const messages = []
      members.forEach((member) => {
        messages.push({
          form: this.storeSetting?.smsSetting?.senderName ? this.storeSetting.smsSetting.senderName.toUpperCase() : 'SMS',
          to: this.telFormatter(member.tel, member.telInfo?.dialCode),
          text: this.personalizing(member, this.promotion, this.form.message),
          member: {
            id: member.id,
            tel: this.telFormatter(member.tel, member.telInfo?.dialCode),
            firstName: member.firstName,
            lastName: member.lastName
          }
        })
      })

      if (messages.length > 0) {
        await this.sendSmsMessage({ messages })
      }
    },
    async preparedToSendLine (members) {
      const messages = []
      members.forEach((member) => {
        const found = member.otherOptions.find((option) => option.key === 'line-uid')
        const lineUid = found ? found.value : null
        if (lineUid) {
          const tempMessage = this.lineMessage.map((each) => {
            let temp = each
            if (each.type === 'text') {
              temp = {
                type: 'text',
                text: this.personalizing(member, this.promotion, each.text)
              }
            } else if (each.type === 'image') {
              temp = {
                type: 'image',
                originalContentUrl: each.originalContentUrl,
                previewImageUrl: each.originalContentUrl
              }
            }

            return temp
          })

          messages.push({
            to: [lineUid],
            messages: tempMessage,
            member: {
              id: member.id,
              tel: this.telFormatter(member.tel, member.telInfo?.dialCode),
              firstName: member.firstName,
              lastName: member.lastName
            }
          })
        }
      })

      if (messages.length > 0) {
        await this.sendLineMessage({ data: messages })
      }
    },
    personalizing (member, promotion, messages) {
      let messageTransformed = messages
      PersonalizeMutator.personal.forEach((variable) => {
        const sampleValue = variable.value.replace('$', '')
        if (PersonalizeMutator[sampleValue]) {
          const newText = member[sampleValue] || '[no data]'
          messageTransformed = messageTransformed.replaceAll(variable.value, newText)
        }
      })

      const promotionsMutator = {
        code: promotion.code,
        expireCode: promotion.dateRange?.endDate ? this.$dayjs(promotion.dateRange.endDate).format('DD/MMMM/YYYY HH:mm') : '',
        discount: `${promotion.discountNumber} ${promotion.discountType === 'amount' ? 'บาท' : '%'}`
      }

      PersonalizeMutator.promotion.forEach((variable) => {
        const sampleValue = variable.value.replace('$', '')
        if (PersonalizeMutator[sampleValue]) {
          const newText = promotionsMutator[sampleValue] || '[no data]'
          messageTransformed = messageTransformed.replaceAll(variable.value, newText)
        }
      })

      messageTransformed = messageTransformed.replaceAll('$memberCode', this.memberCode(member.tel, this.csvContents))

      return messageTransformed
    },
    memberCode (tel, csv) {
      const found = csv.find((each) => `+${each.tel}` === tel)

      return found ? found.code : ''
    },
    async sendLineMessage (payload) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'SENDING LINE MESSAGES...'
        })

        await LineService.sendMulti(payload)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[SEND LINE ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setSnackbar({
          value: true,
          message: 'SEND LINE MESSAGE(S) COMPLETE',
          type: 'success'
        })
        this.setLoading({ active: false })
      }
    },
    async sendSmsMessage (payload) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'SENDING SMS MESSAGES...'
        })

        await SmsService.sendMulti(payload)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[SEND SMS ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setSnackbar({
          value: true,
          message: 'SEND SMS MESSAGE(S) COMPLETE',
          type: 'success'
        })
        this.setLoading({ active: false })
      }
    },
    memberTelsCompute (tels) {
      this.form.members = [...new Set(tels.map((tel) => this.telFormatter(tel)))]
    }
  }
}
</script>

<style scoped>
.main-row {
  padding: 10px 10px;
}

.template-text {
  white-space: pre-line;
}

.text-preview-box {
  white-space: pre-wrap;
  line-height: 1.35em;
}

.csv-table-header-box {
  padding: 2px;
  border: 1px solid black;
}

</style>
