<template>
  <v-file-input
    :disabled="disabled"
    :label="label"
    accept=".csv"
    color="secondary"
    outlined
    dense
    hide-details
    :loading="loading"
    @change="fileHandler($event)" />
</template>

<script>
const fileReader = new FileReader()

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Select file'
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    fileHandler (file) {
      if (file) {
        fileReader.readAsText(file, 'utf8')
        fileReader.onload = (readerEvent) => {
          const contents = readerEvent.target.result.split('\n')
          const result = []
          contents.forEach((line) => {
            if (line.length > 0) {
              const text = line
                .replaceAll('"', '')
                .replaceAll('\r', '')
                .split(',')

              result.push(text)
            }
          })
          this.$emit('input', this.mapData(result))
        }
      } else {
        this.$emit('input', null)
      }
    },
    mapData (contents) {
      const headers = contents.splice(0, 1)[0]
      const mapped = contents.map((content) => {
        const result = {}
        headers.forEach((header, index) => {
          result[header] = content[index]
        })

        return result
      })

      return {
        headers,
        results: mapped
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
