const firstName = () => '<b>[firstname]</b>'
const lastName = () => '<b>[lastname]</b>'
const tel = () => '<b>[telephone no.]</b>'
const memberType = () => '<b>[type of member]</b>'
const birthDate = () => '<b>[birthdate]</b>'
const code = () => '<b>[code]</b>'
const discount = () => '<b>[discount]</b>'
const expireCode = () => '<b>[expire code]</b>'
const spending = () => '<b>[spending]</b>'
const memberCode = () => '<b>[member\'s code]</b>'
const personal = [
  {
    label: 'ชื่อจริง',
    value: '$firstName'
  },
  {
    label: 'นามสกุล',
    value: '$lastName'
  },
  {
    label: 'เบอร์โทร',
    value: '$tel'
  },
  {
    label: 'ประเภทสมาชิก',
    value: '$memberType'
  },
  {
    label: 'วันเกิด',
    value: '$birthDate'
  },
  {
    label: 'ยอดซื้อสะสม',
    value: '$spending'
  }
]
const promotion = [
  {
    label: 'โปรโมชั่นโค้ด',
    value: '$code'
  },
  {
    label: 'วันหมดอายุโค้ดส่วนลด',
    value: '$expireCode'
  },
  {
    label: 'ส่วนลดโปรโมชั่นโค้ด',
    value: '$discount'
  }
]

export default {
  firstName,
  lastName,
  tel,
  memberType,
  birthDate,
  code,
  discount,
  expireCode,
  spending,
  personal,
  promotion,
  memberCode
}
