/* eslint-disable max-len */
export default [
  {
    title: 'วันเกิด',
    text: 'พิเศษเฉพาะ VVIP VIP MEMBER ที่เกิดเดือนกันยายนเท่านั้น! รับส่วนลด 20% (เฉพาะสินค้าราคาปกติ)\nใช้ได้ตั้งแต่วันนี้ - 30 ก.ย. นี้เท่านั้น\nVoucher No. : $code\nใช้ได้ที่ GENTLEWOMAN ทุกสาขา (ยกเว้น Department Store)\nและเว็บไซต์ www.gentlewomanonline.com'
  },
  {
    title: 'Code 300',
    text: 'You have a THB300 GENTLEWOMAN voucher which expires $expiredateEN. Voucher no:$code. Redeem at participating GENTLEWOMAN stores. Min spending THB1,000. T&C apply.'
  },
  {
    title: 'MEMBER > VIP : ข้อความแสดงความยินดีเมื่อเปลี่ยนสถานะจาก Member เป็น VIP',
    text: 'CONGRATULATIONS! คุณได้รับการอัพเกรดเป็นสมาชิกระดับ VIP\nโดยจะได้รับสิทธิพิเศษต่างๆ ดังนี้\n- ได้รับโค้ดส่วนลดมูลค่า 300 บาท (สำหรับสินค้าราคาปกติโดยมียอดซื้อขั้นต่ำ 1,000 บาท)\n- ได้รับโค้ดส่วนลด 5% ทุกการช้อป สำหรับสินค้าราคาปกติ\n- ได้รับโค้ดส่วนลดพิเศษในเดือนเกิด\n- ได้รับสิทธิ์ในการซื้อสินค้าคอลเลคชั่นพิเศษก่อนใคร\n- ได้รับของสมนาคุณในโอกาสพิเศษ'
  },
  {
    title: 'SMS 300 : ข้อความที่ส่งหาลูกค้าที่เพิ่งได้รับการเปลี่ยนสถานะเป็น VIP (ส่งลำดับสอง)',
    text: 'คุณได้รับส่วนลดมูลค่า 300 บาท สำหรับซื้อสินค้าราคาปกติขั้นต่ำ 1,000 บาท\nเพียงใช้โค้ดส่วนลด $code (หมดอายุ $expiredateTH) ที่ร้าน Gentlewoman ทุกสาขา (ยกเว้น Department store) และ www.gentlewomanonline.com'
  },
  {
    title: 'SMS 5% : ข้อความที่ส่งหาลูกค้าที่เพิ่งได้รับการเปลี่ยนสถานะเป็น VIP (ส่งลำดับสาม)',
    text: 'คุณได้รับส่วนลด 5% ในการซื้อสินค้าครั้งถัดไป (สินค้าราคาปกติ)\nเพียงใช้โค้ดส่วนลด $code ที่ร้าน Gentlewoman ทุกสาขา (ยกเว้น Department store) และ www.gentlewomanonline.com'
  },
  {
    title: 'VIP > VVIP : ข้อความแสดงความยินดีเมื่อเปลี่ยนสถานะเป็น VVIP',
    text: 'CONGRATULATIONS! คุณได้รับการอัพเกรดเป็นสมาชิกระดับ VVIP\nโดยจะได้รับสิทธิพิเศษต่างๆ ดังนี้\n- ได้รับโค้ดส่วนลด 10% ทุกการช้อป สำหรับสินค้าราคาปกติ\n- ได้รับโค้ดส่วนลดพิเศษในเดือนเกิด\n- ได้รับสิทธิ์ในการซื้อสินค้าคอลเลคชั่นพิเศษก่อนใคร\n- ได้รับของสมนาคุณในโอกาสพิเศษ'
  },
  {
    title: 'MEMBER > VVIP',
    text: 'CONGRATULATIONS! คุณได้รับการอัพเกรดเป็นสมาชิกระดับ VVIP\nโดยจะได้รับสิทธิพิเศษต่างๆ ดังนี้\n- ได้รับโค้ดส่วนลดมูลค่า 300 บาท (สำหรับสินค้าราคาปกติโดยมียอดซื้อขั้นต่ำ 1,000 บาท)\n- ได้รับโค้ดส่วนลด 10% ทุกการช้อป สำหรับสินค้าราคาปกติ\n- ได้รับโค้ดส่วนลดพิเศษในเดือนเกิด\n- ได้รับสิทธิ์ในการซื้อสินค้าคอลเลคชั่นพิเศษก่อนใคร\n- ได้รับของสมนาคุณในโอกาสพิเศษ'
  },
  {
    title: 'sms 10%',
    text: 'คุณได้รับส่วนลด 10% ในการซื้อสินค้าครั้งถัดไป (สินค้าราคาปกติ)\nเพียงใช้โค้ดส่วนลด $code ที่ร้าน Gentlewoman ทุกสาขา (ยกเว้น Department store) และ www.gentlewomanonline.com'
  }
]
