import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class SmsProvider extends HttpRequest {
  getSmsMessages (query) {
    this.setHeader(getAuthToken())
    return this.get('/sms', query)
  }

  sendMulti (payload) {
    this.setHeader(getAuthToken())
    return this.post('/sms/multi', payload)
  }
}

export default SmsProvider
