<template>
  <div class="line-box">
    <v-row>
      <v-col
        cols="12"
        class="ma-4">
        ข้อความที่ต้องการส่งผ่านไลน์
      </v-col>
      <v-col
        v-for="(message, index) in messages"
        :key="`line-message-${index}`"
        cols="12"
        class="d-flex justify-center">
        <div class="message-box">
          <div
            class="message-box-header">
            <p v-if="message.type === 'text'">
              Text
            </p>
            <p v-else-if="message.type === 'image'">
              Image
            </p>
            <v-btn
              x-small
              icon
              @click="removeMessage(index)">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </div>

          <div
            v-if="message.type === 'text'"
            class="message-box-content">
            <v-textarea
              v-model="messages[index].text"
              outlined
              hide-details />
          </div>

          <div
            v-else-if="message.type === 'image'"
            class="message-box-content">
            <gw-drop-file
              v-model="messages[index].originalContentUrl"
              :show-button="false"
              s3-path="/broadcast"
              caption="คลิกเพื่อเพิ่มรูปภาพ" />
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-center">
        <div class="action-button-box">
          <v-btn
            small
            @click="addMessage('text')">
            <v-icon small>
              mdi-plus-thick
            </v-icon> Text
          </v-btn>
          <v-btn
            small
            @click="addMessage('image')">
            <v-icon small>
              mdi-plus-thick
            </v-icon> Image
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    messages: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    addMessage (type) {
      if (type === 'text') {
        this.messages.push({ type, text: '' })
      } else if (type === 'image') {
        this.messages.push({ type, originalContentUrl: '', previewImageUrl: '' })
      }
    },
    removeMessage (index) {
      this.messages.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.line-box {
  width: 100%;
  margin-bottom: 8px;
  border:1px solid rgba(120,120,120,0.4);
  border-radius: 5px;
  min-height: 140px;
}

.action-button-box {
  margin: 30px 0px;
  width: 20%;
  display: flex;
  justify-content: space-around;
}

.message-box {
  width: 60%;
  border:1px solid rgba(120,120,120,0.4);
  margin: 24px 0px;
  min-height: 150px;
  border-radius: 5px;
}

.message-box-content {
  padding: 5px;
  width: 100%;
  height: 100%;
}

.message-box-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  width: 100%;
  padding: 2px 10px;
  height: 30px;
  border:1px solid rgba(120,120,120,0.4);
}
</style>
