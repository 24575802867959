import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class LineProvider extends HttpRequest {
  getLineMessages (query) {
    this.setHeader(getAuthToken())
    return this.get('/line', query)
  }

  sendMulti (payload) {
    this.setHeader(getAuthToken())
    return this.post('/line/message-multicast', payload)
  }
}

export default LineProvider
