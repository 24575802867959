<template>
  <div class="email-box">
    <v-row>
      <v-col
        cols="12"
        class="ma-4">
        ข้อความที่ต้องการส่งผ่านอีเมล
      </v-col>
      <v-col
        cols="12"
        class="ma-4">
        email box
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-center">
        <div class="action-button-box">
          <v-btn
            small
            @click="addMessage('text')">
            <v-icon small>
              mdi-plus-thick
            </v-icon> Text
          </v-btn>
          <v-btn
            small
            @click="addMessage('image')">
            <v-icon small>
              mdi-plus-thick
            </v-icon> Image
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {

  }
</script>

<style scoped>
.action-button-box {
  margin: 30px 0px;
  width: 20%;
  display: flex;
  justify-content: space-around;
}
</style>
