<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px">
    <v-card class="pa-5">
      <div v-if="promotion.code">
        <v-card-title>
          Promotion Detail
        </v-card-title>
        <v-card-text
          v-for="(header, index) in headers"
          :key="`promotion-key-${index}`"
          class="d-flex py-1">
          <b>{{ wordsFormatter(header) }} : </b>
          <p class="ml-2 mb-0">
            {{ avoidNullValue(promotion, header) }}
          </p>
        </v-card-text>
      </div>
      <div v-else>
        <v-card-title>
          Promotion Detail
        </v-card-title>
        <v-card-text>
          <b>ไม่มีรายละเอียดดค้ดส่วนลด</b>
        </v-card-text>
      </div>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import humps from 'humps'

export default {
  props: {
    promotion: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    headers () {
      return Object.keys(this.promotion)
    }
  },
  methods: {
    wordsFormatter (text) {
      return humps.decamelize(text).split('_').map((each) => each[0].toUpperCase() + each.slice(1)).join(' ')
    },
    avoidNullValue (object, properties, defaultValue = '-') {
      return object[properties] || defaultValue
    }
  }
}
</script>
